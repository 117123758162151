:root {
  --color-text: #{$c-dark-gray};
  --color-link: #{$c-green};
  --font-family: #{$font-content};
  --line-height: 1.5;
}

body {
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: underline;

  transition: text-decoration-color 300ms;

  &:hover, &:focus {
    text-decoration-color: transparent;
    filter: none;
  }
}

.font-heading {
  @include heading;
}

h1, h2, h3, h4, h5, h6 {
  @include heading;
}

// <em> is used to add green text to headings
h1, h2, h3, h4, h5, h6 {
  em {
    color: $c-green;
    font-style: normal;
  }
}

a {
  font-weight: 400;
}

address {
  font-style: normal;
}
