@font-face {
	font-family: 'Domine';
	src: url('../fonts/Domine-Regular.eot');
	src: url('../fonts/Domine-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Domine-Regular.svg#Domine-Regular') format('svg'),
		url('../fonts/Domine-Regular.ttf') format('truetype'),
		url('../fonts/Domine-Regular.woff') format('woff'),
		url('../fonts/Domine-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Domine';
	src: url('../fonts/Domine-Medium.eot');
	src: url('../fonts/Domine-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Domine-Medium.svg#Domine-Medium') format('svg'),
		url('../fonts/Domine-Medium.ttf') format('truetype'),
		url('../fonts/Domine-Medium.woff') format('woff'),
		url('../fonts/Domine-Medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Domine-Bold';
	src: url('../fonts/Domine-Bold.eot');
	src: url('../fonts/Domine-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Domine-Bold.svg#Domine-Bold') format('svg'),
		url('../fonts/Domine-Bold.ttf') format('truetype'),
		url('../fonts/Domine-Bold.woff') format('woff'),
		url('../fonts/Domine-Bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
