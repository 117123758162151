@use "sass:math";

%container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: math.div(-$grid-gutter-width, 2);
  box-sizing: border-box;
}

// NOTE: This is a purposefully narrow website.
%row {
  display: block;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}