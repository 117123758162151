@use "sass:math";

$menu-size-mobile: 16px;
$menu-size-desktop: 21px;
$menu-transition-duration: 300ms;

body.menu-expanded {
  overflow-y: hidden;
}

body {
  padding-top: 128px;
}

#site-header {
  transition: all $menu-transition-duration;
  max-width: none;
  padding: 0;

  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 128px;
  top: 0;

  &-inner {
    position: relative;
    padding: 24px 1rem;
    @include bp-desktop {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .site-logo {
    // padding-right: $menu-size-mobile*4 + 10px; //~74px
    box-sizing: border-box;
    @include bp-desktop {
      display: block;
      text-align: center;
      // padding-right: $menu-size-desktop*4 + 20px;
      // margin-left: math.div(-$grid-gutter-width, 2);
    }

    img {
      width: 260px;
      height: auto;
      max-width: 100%;
      @include bp-desktop {
        width: 360px;
      }

      transition: $menu-transition-duration filter linear, $menu-transition-duration -webkit-filter linear;
    }
  }
}

#site-header-top {
  text-align: left;
  @include content-width;
}

#site-nav {
  opacity: 0;
  max-height: 0;
  transition: all $menu-transition-duration;
  padding-top: 30px;
  @include bp-desktop {
    padding-top: 60px;
  }

  @include content-width;

  > ul {
    display: block;
    margin-top: 0;

    > li {
      display: block;

      > a {
        @include font-size-large;
        padding: 12px 30px;
        @include bp-desktop {
          @include font-size-xlarge;
          padding: 20px 30px;
        }
        color: $c-white;
        font-family: $font-heading;
        text-decoration: none;
        transition: color 300ms;

        &:hover {
          color: $c-pale-gray;
        }
      }
    }
  }
}

#site-nav-toggle {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $c-pale-gray;
  text-decoration: none;
  color: $c-green;
  font-size: $menu-size-mobile;
  width: $menu-size-mobile*4;
  height: $menu-size-mobile*4;
  text-align: center;
  line-height: $menu-size-mobile*4;
  transition: background-color $menu-transition-duration, color $menu-transition-duration;
  @include bp-desktop {
    font-size: $menu-size-desktop;
    width: $menu-size-desktop*4;
    height: $menu-size-desktop*4;
    text-align: center;
    line-height: $menu-size-desktop*4;
  }

  &:hover {
    color: $c-pale-gray;
    background-color: $c-green;
  }

  &:before {
    @include icon($icon-menu);
  }

  &:after {
    @include icon($icon-close);
    opacity: 0;
  }

  &:before, &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity $menu-transition-duration;
  }
}

#site-header.expanded {
  background-color: $c-green;
  min-height: 100vh;
  height: 100vh;
  overflow: scroll;

  #site-nav {
    display: block !important;
    max-height: 10000000px;
    opacity: 1;
  }

  .site-logo {
    img {
      filter: brightness(0) invert(1);
    }
  }

  #site-nav-toggle {
    background-color: inherit;
    color: $c-pale-gray;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}
