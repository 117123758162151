@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-Regular.eot');
	src: url('../fonts/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-Regular.svg#DMSans-Regular') format('svg'),
		url('../fonts/DMSans-Regular.ttf') format('truetype'),
		url('../fonts/DMSans-Regular.woff') format('woff'),
		url('../fonts/DMSans-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-Italic.eot');
	src: url('../fonts/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-Italic.svg#DMSans-Italic') format('svg'),
		url('../fonts/DMSans-Italic.ttf') format('truetype'),
		url('../fonts/DMSans-Italic.woff') format('woff'),
		url('../fonts/DMSans-Italic.woff2') format('woff2');
	font-weight: normal;
	font-style: oblique;
}

@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-Medium.eot');
	src: url('../fonts/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-Medium.svg#DMSans-Medium') format('svg'),
		url('../fonts/DMSans-Medium.ttf') format('truetype'),
		url('../fonts/DMSans-Medium.woff') format('woff'),
		url('../fonts/DMSans-Medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-MediumItalic.eot');
	src: url('../fonts/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg'),
		url('../fonts/DMSans-MediumItalic.ttf') format('truetype'),
		url('../fonts/DMSans-MediumItalic.woff') format('woff'),
		url('../fonts/DMSans-MediumItalic.woff2') format('woff2');
	font-weight: 400;
	font-style: oblique;
}

@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-Bold.eot');
	src: url('../fonts/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-Bold.svg#DMSans-Bold') format('svg'),
		url('../fonts/DMSans-Bold.ttf') format('truetype'),
		url('../fonts/DMSans-Bold.woff') format('woff'),
		url('../fonts/DMSans-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DMSans';
	src: url('../fonts/DMSans-BoldItalic.eot');
	src: url('../fonts/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg'),
		url('../fonts/DMSans-BoldItalic.ttf') format('truetype'),
		url('../fonts/DMSans-BoldItalic.woff') format('woff'),
		url('../fonts/DMSans-BoldItalic.woff2') format('woff2');
	font-weight: 700;
	font-style: oblique;
}