body > footer {
  max-width: none;

  padding: 0;

  font-size: 13px;
  line-height: 16px;

  letter-spacing: 0.07em;

  .footer-top {
    --color-link: #{$c-dark-gray};
    background-color: $c-white;

    padding: 60px 15px 30px;
    @include bp-desktop {
      padding-top: 73px;
      padding-bottom: 49px;
    }

    .inner {
      @include content-width;

      display: flex;
      flex-direction: column;
      align-items: center;

      @include bp-desktop {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    .footer-section {
      padding-bottom: 24px;
      @include bp-desktop {
        padding-bottom: 0;
      }
    }

    .contact {
      color: $c-green;
      line-height: 20px;
      font-weight: bold;

      a {
        color: inherit;
      }
    }

    .certifications {

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        width: 118px;

        li {
          padding: 5px;
          box-sizing: border-box;
        }

        .half-width {
          flex-basis: 50%;
        }

        .full-width {
          flex-basis: 100%;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    #footer-nav {
      margin-bottom: 0;

      ul {
        margin: -5px -0.5rem 0;

        > li {
          display: block;
          padding-bottom: 5px;
          text-align: center;
          @include bp-desktop {
            text-align: left;
          }

          a {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  .footer-bottom {
    --color-link: #{$c-black};
    background-color: $c-pale-gray;

    padding: 32px 15px;

    .inner {
      @include content-width;

      display: flex;
      flex-direction: column;
      align-items: center;

      @include bp-desktop {
        flex-direction: row;
        justify-content: space-between;
      }
    }


    .branding {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;

      @include bp-desktop {
        padding-top: 0;
        flex-direction: row;
        justify-content: flex-start;
      }

      img {
        padding-bottom: 24px;
        @include bp-desktop {
          padding-bottom: 0;
          padding-right: 24px;
        }
      }

      .copy {
        text-align: center;
      }
    }

    #legal-nav {
      margin-bottom: 0;
      justify-self: flex-end;
      @include bp-desktop {
        order: 1000;
      }


      ul {
        text-align: center;
        margin-top: -5px;

        > li {
          margin-left: 0.25em;
          margin-right: 0.25em;

          &:after {
            display: inline-block;
            content: "|";
            margin-left: 0.5em;
          }

          &:last-child {
            &:after {
              content: none;
            }
          }

          a {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}