.page-title, .section-title, .subtitle {
  @include heading;
  text-align: center;
  color: $c-dark-gray;
  margin-bottom: 1.5em;

  em {
    color: $c-green;
    font-style: normal;
  }
}

.page-title, .section-title {
  @include font-size-xlarge;
}

.section-title {
  margin-bottom: 16px;
}

.subtitle {
  @include font-size-large;
}

.hero {
  margin-bottom: 80px;

  @include box-border;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.copy {
  text-align: center;
}

.section-title, .copy, .subtitle {
  max-width: 549px;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  padding: 16px 24px;
  color: $c-white;
  background-color: $c-green;
  font-family: $font-heading;
  transition: background-color 300ms;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &:hover, &:focus {
    background-color: $c-dark-green;
    text-decoration: none;
  }

  &--light {
    background-color: $c-pale-gray;
    color: $c-green;

    &:hover, &:focus {
      background-color: $c-light-gray;
    }
  }
}

.callout {
  @include box-border($c-green);
  background-color: $c-pale-gray;
  margin-bottom: 40px;
  padding: 63px 68px;

  display: flex;
  flex-direction: column;
  @include bp-desktop {
    flex-direction: row;

    > * {
      padding: 20px;
      flex-basis: 100%;
      flex-shrink: 2;
      box-sizing: border-box;
    }
  }

  &--dark {
    color: $c-white;
    background-color: $c-dark-green;

    .subtitle {
      color: inherit;
    }

    &:after {
      border-color: $c-white;
    }

    .btn {
      background-color: $c-pale-gray;
      color: $c-dark-green;

      &:hover, &:focus {
        background-color: $c-light-gray;
      }
    }
  }

  &--image {
    padding: 0;

    .text {
      padding: 60px 40px 30px;
      @include bp-desktop {
        padding: 60px 80px;
      }
    }

    .image {
      padding: 31px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .title {
    text-align: center;
    @include bp-desktop {
      text-align: left;
    }
    margin-top: 0;
    margin-bottom: 4px;

    @include heading;
    @include font-size-large;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include bp-desktop {
      align-items: flex-start;
    }
    min-height: 100%;

    .actions {
      margin-top: auto;
      justify-self: flex-end;
    }

    &-wide {
      @include bp-desktop {
        flex-shrink: 1;
      }
    }

    &-center {
      align-items: center;
    }
  }

  .actions {
    padding-top: 2em;
    @include bp-desktop {
      padding-top: 20px;
    }
  }

  .text + .text {
    padding-top: 2em;
    @include bp-desktop {
      padding-top: 20px;
    }
  }

}

.box {
  background-color: $c-pale-gray;
  padding: 40px;
  margin-bottom: 40px;

  .title {
    text-align: left;
    margin-bottom: 4px;
    @include font-size-large;
    @include heading;
  }

  .action {
    margin-top: 24px;

    a {
      text-decoration: none;
      font-weight: 700;
      transition: color 300ms;

      &:after {
        @include icon($icon-chevron);
        padding-left: 1ex;
        font-size: 0.6em;
      }

      &:hover, &:focus {
        color: $c-dark-green;
      }
    }
  }
}

.actions {
  text-align: center;
  margin: -8px;

  .btn {
    margin: 8px;
    white-space: nowrap;
  }
}

.section {
  margin-top: 80px;
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }
}