@mixin font-size-large() {
  font-size: 24px;
  line-height: 32px;
}

@mixin font-size-xlarge() {
  font-size: 32px;
  line-height: 40px;
}

@mixin heading {
  font-family: $font-heading;
  font-weight: 700;
}

@mixin bp-tablet {
  @media screen and (min-width: $screen-xs) {
    @content;
  }
}

@mixin bp-desktop {
  @media screen and (min-width: $screen-sm) {
    @content;
  }
}

@mixin content-width {
  margin: 0 auto;
  max-width: var(--width-content);
  padding-left: $grid-gutter-width-mobile;
  padding-right: $grid-gutter-width-mobile;
  @include bp-tablet {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

@mixin box-border($color: $c-white) {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    border: 1px solid $color;
    left: 16px;
    right: 16px;
    top: 16px;
    bottom: 16px;
    pointer-events: none;
  }
}

@mixin nice-ul {

  list-style: none;
  padding-left: 1.5em;

  li {
    &:before {
      content: "\2022";
      color: $c-green;
      font-weight: bold;
      display: inline-block;
      width: 0.75em;
      margin-left: -0.75em;
      font-size: 150%;
      line-height: calc(var(--line-height) * 0.66666);
      vertical-align: middle;
    }

    p {
      margin: 0;

      &:first-child {
        display: inline-block;
      }
    }
  }
}